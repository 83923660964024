import Logo from '../../assets/images/logo2.png'
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location  = useLocation();
    
    return (
      <div className="row boxshadow">
        <div className="col-4 py-2">
            <img src={Logo} className="adminlogo" alt="Biorev" />
        </div>
        <div className="col-4 text-center p-2">
          { 
            (location.pathname == '/admin')
            ?<a className='btn btn-info mx-1 btn-sm' href='/admin/olddata'>Show Old Data</a>
            :<a className='btn btn-danger mx-1 btn-sm' href='/admin'>Show New Data</a>
          }
        </div>
        <div className="col-4 text-end py-2">
          <h6 className="mt-2"> Welcome Admin,</h6>
        </div>
      </div>
    );
  }
  
  export default Header;