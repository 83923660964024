import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

const columns = [
    {
        name: "First Name",
        selector: (row) => row.first_name,
        sortable: false
    },
    {
        name: "Last Name",
        selector: (row) => row.last_name,
        sortable: false
    },
    {
        name: "Company",
        selector: (row) => row.company,
        sortable: false
    },
    {
        name: "Segment",
        selector: (row) => row.segment,
        sortable: false
    },
    {
        name: "Contact No",
        selector: (row) => row.mobile,
        sortable: false,
        cell: row => {
            return row.mobile.replace(/[^0-9]/g, "")
        }
    },
    {
        name: "Visit Date",
        selector: (row) => row.predefined_date,
        sortable: false,
        cell: row => {return (row.predefined_date === null)?'--':<><Moment format="ddd, MMM Do YYYY" titleFormat="D MMM YYYY" withTitle>{row.predefined_date}</Moment> </>},
    },
    {
        name: "Start Time",
        selector: (row) => row.start_time,
        sortable: false,
        cell: row => {
            return (!row.hasOwnProperty('start_time'))?'---':row.start_time.replace('am', " AM").replace('pm', " PM")
        }
    },
    {
        name: "End Time",
        selector: (row) => row.end_time,
        sortable: false,
        cell: row => {
            return (!row.hasOwnProperty('end_time'))?'---':row.end_time.replace('am', " AM").replace('pm', " PM")
        }
    },
    {
        name: "Actual Time",
        selector: (row) => row.actual_date,
        cell: row => {return (row.actual_date === null)?'--':<><Moment tz="America/Phoenix" format="MMM D, HH:mm A" titleFormat="D MMM YYYY, HH:mm A" withTitle>{row.actual_date}</Moment> </>},
        sortable: false
    },
];
export default columns;