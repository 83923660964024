import axios from 'axios';

const url = 'https://meetings.biorev.com/api/posts';
//const url = 'http://localhost:5000/api/posts';
//const url2 = 'http://localhost:5000/api/oldposts';
const url2 = 'https://meetings.biorev.com/api/oldposts';

export const fetchPosts = () => axios.get(url);
export const fetchOldPosts = () => axios.get(url2);
export const createPost = (newPost) => axios.post(url, newPost);
export const likePost = (id) => axios.patch(`${url}/${id}/likePost`);
export const updatePost = (id, updatedPost) => axios.patch(`${url}/${id}`, updatedPost);
export const deletePost = (id) => axios.delete(`${url}/${id}`);
