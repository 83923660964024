import React, { useEffect } from 'react';
import Header from './components/admin/Header';
import OldPosts from './components/admin/OldPosts';
import { useDispatch } from 'react-redux';

import { getOldPosts } from './actions/posts';


const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOldPosts());
  });

  return (
    <div className="App">
      <div className="container-fluid bg-white">
        <Header />
        <OldPosts />
      </div>
    </div>
  );
};

export default App;
